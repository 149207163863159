<template>
<div class="col-12">
<div class="col-6 float-start" style="padding:1%;">
<b>フレームの幅</b> <input class="form-control" type="text" v-model="w"  ><br>
</div>
<div class="col-6 float-end" style="padding:1%;">
<b>フレームの高さ</b> <input  class="form-control" type="text" v-model="h" >
</div>
<div class="col-12 float-start" style="padding:1%;">

  <b >初期化ファイル</b> 
  <br>
  <div class="v3ti" style="border:none;">
  <span class="v3ti-tag" v-for="v in filenames" v-bind:key="v"  >
  <span style="cursor:pointer;" @click="addInitializationUrl(v)" >{{ v }}</span>
  </span>
  </div>

  <vue3-tags-input 
  v-model="tag"
  :autocomplete-items="filenames"
  :tags="initializationUrls"
  @on-tags-changed="newTags => { initializationUrls = newTags;  }"    
  placeholder="初期化ファイル" />
  </div>
  <div class="col-12 float-start" style="padding:1%;">
  <b>初期化ファイルのアップロード</b> <input @change="uploadFile"   class="form-control" type="file"  multiple="multiple" >
  </div>

</div>
<div class="col-12 float-start" style="padding:1%;">
<button class="btn btn-dark" v-on:click="update" > 実行 </button>
</div>
<hr>

<div>
<CPlateauView ref="view" :path="path"> </CPlateauView>
</div>
</template>

<script>

import CPlateauView from "../../components/chart/PlateauView.vue";
import Vue3TagsInput from 'vue3-tags-input';

export default {
  name: 'PlateauView',
  components:  { CPlateauView,Vue3TagsInput } ,
  data : function() {
      return { 

        w : "100%" , 
        h : "800px" , 
        initializationUrls : [],
        path: "",
        tag: '',
        filenames : []
      }
  },
   watch: {
    initializationUrls (newv, oldv){
      
      if (newv.length > oldv.length){ return; }

      const temps=[];
      oldv.map((v)=>{

         if(!newv.includes(v)){
            temps.push(v);
         }

      });

      for ( var i=0 ; i<temps.length;i++){
        let b= true;
        

            let hasName =false;
            for ( let k=0; k < this.filenames.length ;k++){
              
              hasName=(this.filenames[k]===temps[i]);
               if (hasName){ break; }
            }
            if(!hasName){

              this.filenames.push( temps[i] ); 
            }
        }
    }
  },
  async created()  {

   
    try{
     const res =await fetch( "/api/v3/GetTerriaInitFileNames" );
    //  const res =await fetch( "http://localhost:7071/api/GetTerriaInitFileNames" ); 
      console.log(res);
     if(res.ok){ 

        const text = await res.text();
        this.filenames = (text.replaceAll('.json','').slice( 0, -1 )).split(',').filter(Boolean);
         if(  this.filenames.length !== 0 ){
            this.initializationUrls.push( this.filenames.pop());
         }
     }
     }catch(e){console.log(e); }
    }
    , methods: {
      addInitializationUrl (v){  
        
        for ( let i in this.initializationUrls){ 
          if(this.initializationUrls[i] ===v){ return; }
        } 
        
        this.initializationUrls.push(v);
        
        var temp =this.filenames.pop();
        var temps=[];
        while(temp){

            if(temp!==v){
              temps.push(temp);
            }
            temp =this.filenames.pop();
        }
        while(temps.length!=0){
          
          this.filenames.push( temps.pop() );
        }
  
      },
       async uploadFile (e){
        e.preventDefault();
                let files = e.target.files;

        const formData = new FormData();
    formData.append("terriainitfile", files[0]);  // ファイル内容を詰める

    const param = {
      method: "POST",
      headers: { 'content-type': 'multipart/form-data' },
      body: formData
    }
      try{

       if(! files[0].name.includes('.json')){
           this.$emit('show-flash',{ "message":"JSONファイルをアップロードしてください。","type": "warning"});
  
       }
      
      const res = await fetch( "/api/v3/saveTerriaInitFile" , param)
      //const res = await fetch( "http://localhost:7071/api/saveTerriaInitFile" , param)
      
      if(res.ok){ 

        this.$emit('show-flash',{ "message":"保存しました。","type": "success"}); 
        this.filenames.push( files[0].name.replace('.json',''));
        return ;
        }
      }catch(e){  console.log(e); }
  
        this.$emit('show-flash',{ "message":"保存に失敗しました。","type": "success"});
  
       },
       async update (){
         const param = "?w="+this.w + "&h="+this.h+"&initializationUrls="+this.initializationUrls.join(',') ;
          this.path =  param;
        
        if ( window.parent !== window &&  !window.isCrossOrigin ) { 
          
               window.parent.history.pushState( {}, '', new URL( window.parent.location.href).pathname + param ) ;  
            }

        this.$refs.view.showData(this.path);
        
        }
    }
}

</script>
<style>
header,nav{
display: none !important;
}

main{
width: 100% !important ;
}

.container {
  width: 200px;
  margin: 30px;
}

.input {
  width: 100%;
  margin: 0px;
  padding-right: 5px;
}

.eye::after {
  font-family: 'FontAwesome';
  content: "\f06e";
}

.eye-slash::after {
  font-family: 'FontAwesome';
  content: "\f070";
}

.input-icon {
  position: relative;
  float: right;
  margin-top: -25px;
}
</style>